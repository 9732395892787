import { graphql } from 'gatsby';
import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { textOptions } from '../../util/rich-text';
import { Layout } from '../../components/layout/layout';
import {
  DesktopAuthorCard,
  MobileAuthorCard,
} from '../../components/cards/author-card';
import { PageHeading } from '../../components/layout/page-heading';
import BackButton from '../../components/back-button';

const Event = ({ data }) => {
  const event = data.contentfulEvent;

  return (
    <Layout
      title={event.title}
      image={event.coverPhoto.fixed.src}
      description={event.description.description}
    >
      <div className="max-w-screen-xl mx-auto px-4 md:px-0">
        <BackButton title="Events" link="/events" />
        <div className="mt-6">
          <div className="flex">
            <DesktopAuthorCard author={event.author} />
            <main className="max-w-screen-md md:px-4 md:ml-8">
              <PageHeading title={event.title} />
              <MobileAuthorCard author={event.author} />
              <div className="mt-6">
                {event.content && renderRichText(event.content, textOptions)}
              </div>
            </main>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Event;

export const query = graphql`
  query ($slug: String!) {
    contentfulEvent(slug: { eq: $slug }) {
      title
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            description
            fixed(width: 1200) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
      author {
        name
        description {
          description
          childMarkdownRemark {
            html
          }
        }
        displayPicture {
          fixed(width: 400) {
            width
            height
            src
            srcSet
          }
        }
      }
      description {
        description
      }
      coverPhoto {
        fixed(width: 1200) {
          width
          height
          src
          srcSet
        }
      }
    }
  }
`;
